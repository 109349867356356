<div class="container bg-grey">
  <div class="row">
    <div class="col-md-8">
      <!-- NEW HIGHLIGHT CAROUSEL START vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv -->
      <c-carousel>
        <c-carousel-inner>
          <c-carousel-item *ngFor="let slide of highlightslides">
            <img alt="{{slide.title}}" class="d-block w-100" loading="lazy" src="{{slide.src}}" />
            <c-carousel-caption class="d-none d-md-block title" style="opacity: 0.5;  ">
              <h3>{{slide.title}}</h3>
              <p>{{slide.subtitle}}</p>
            </c-carousel-caption>
          </c-carousel-item>
        </c-carousel-inner>
        <c-carousel-control [routerLink] caption="Previous" direction="prev"></c-carousel-control>
        <c-carousel-control [routerLink] caption="Next" direction="next"></c-carousel-control>
      </c-carousel>
            <!-- NEW HIGHLIGHT CAROUSEL END ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ -->

    </div>
    <div class="col-md-4 text-center">
      <div class="text-center" style="margin: 15px 0 0; border: 1px solid black" hidden>
        <div hidden>
          <h1 id="CountdownTimer" style="background-color: #336699; border-color: #3366cc"></h1>
        </div>
        <div hidden>
          <h3 title="(OSAA Rank, Coaches Poll)    [Wins - Losses]">
            <span style="font-size: large; color: lightgray"> (1) </span>
            Wilsonville <span style="font-size: x-large">[11-1]</span> <br />
            vs
          </h3>
          <h3 title="(OSAA Rank, Coaches Poll)   [Wins - Losses]">
            <span style="font-size: large; color: lightgray"> (3) </span>
            Mountain View <span style="font-size: x-large">[12-0]</span>
          </h3>
          <h4>
            Fri Nov 24&nbsp;&nbsp;7:00pm
            <br />
            TBD
            <br />
            <br />&nbsp;
            <a hidden class="btn btn-info" role="button" href="https://goo.gl/maps/orjcG6Kw7pP2" target="_blank"
              aria-label="Directions to Game" style="background-color: #336699; border-color: #3366cc">
              <img src="/assets/images/misc/mappin.png" alt="Map Icon" width="25" class="rounded" />
              Directions
            </a>
          </h4>
        </div>
      </div>
      <div class="text-center" style="margin: 0 0 0; border: 1px solid black">
        <img
          src="/assets/images/2023/20231124-triplem_5a state football championship game wilsonville vs mountain view_223968.jpg"
          alt="State Champions Trophy" class="d-block w-100 rounded" />
        <br />
        <a class="btn btn-primary w-50" routerLink="/sponsorlist" aria-label="Click to view a list of all our sponsors">
          VIEW ALL OUR SPONSORS
        </a>
        <br />
        <br />
        <!-- NEW SPONSOR CAROUSEL START vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv -->
        <c-carousel>
          <!-- <c-carousel-indicators></c-carousel-indicators> -->
          <c-carousel-inner>
            <c-carousel-item *ngFor="let sponsorSlide of sponsorslides" class="sponsor-carousel-item">
              <img alt="{{sponsorSlide.title}}" class="d-block w-100" loading="lazy" src="{{sponsorSlide.src}}" />
              <c-carousel-caption class="d-none d-md-block">
                <!-- <h3>{{slide.title}}</h3>
                <p>{{slide.subtitle}}</p> -->
              </c-carousel-caption>
            </c-carousel-item>
          </c-carousel-inner>
          <c-carousel-control [routerLink] caption="Previous" direction="prev"></c-carousel-control>
          <c-carousel-control [routerLink] caption="Next" direction="next"></c-carousel-control>
        </c-carousel>
        <!-- NEW SPONSOR CAROUSEL END ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ -->

      </div>
      <br />
    </div>
  </div>
  <br />
  <br />
  <br />
  <br />
</div>

<!-- CountDown Timer script -->
<script>
  // Set the date we're counting down to
  var countDownDate = new Date("Sep 1, 2023 19:00:00").getTime();

  // Update the count down every 1 second
  var x = setInterval(function () {
    // Get today's date and time
    var now = new Date().getTime();

    // Find the distance between now and the count down date
    var distance = countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // Output the result in an element with id="demo"
    //  document.getElementById("Countdown").innerHTML = days + "d " + hours + "h "
    //+ minutes + "m " + seconds + "s ";
    //
    //document.getElementById("CountdownTimer").innerHTML = "TIME TO KICKOFF <BR/>" + days + ":" + hours + ":" + minutes + ":" + seconds;
    document.getElementById("CountdownTimer").innerHTML =
      "TIME TO BANQUET <BR/>" +
      days +
      ":" +
      hours +
      ":" +
      minutes +
      ":" +
      seconds;
    //
    //document.getElementById("CountdownTimer").innerHTML = days + " <br/> Days Football Practice Starts <br/> Feb 8" + ", 2021";

    // If the count down is over, write some text
    if (distance < 0 && distance > -12600000) {
      clearInterval(x);
      document.getElementById("CountdownTimer").innerHTML = "IT'S GAME TIME!";
    } else if (distance < -12600000) {
      // if more than 3 hrs or so, remove the "IT'S GAME TIME!" statement
      // 12600000 ms =3.5 hrs
      // 10800000 ms= 3 hrs
      document.getElementById("CountdownTimer").innerHTML = "";
    }
  }, 1000);
</script>