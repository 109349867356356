{
  "name": "wilsonville-football",
  "version": "2.11.2",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "serve:prod": "ng serve --configuration=production",
    "prebuild:dev": "npm --no-git-tag-version version patch",
    "build:dev": "ng build --configuration development",
    "prebuild:prod": "npm --no-git-tag-version version patch",
    "build:prod": "ng build --configuration production",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "build:stats": "ng build --configuration production --stats-json"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.1.4",
    "@angular/cdk": "^18.1.4",
    "@angular/common": "^18.1.4",
    "@angular/compiler": "^18.1.4",
    "@angular/core": "^18.1.4",
    "@angular/forms": "^18.1.4",
    "@angular/material": "^18.2.0",
    "@angular/platform-browser": "^18.1.4",
    "@angular/platform-browser-dynamic": "^18.1.4",
    "@angular/router": "^18.1.4",
    "@coreui/angular": "~5.2.13",
    "@coreui/coreui": "^5.1.1",
    "@coreui/icons-angular": "~5.2.13",
    "@popperjs/core": "~2.11.8",
    "bootstrap": "^5.3.3",
    "rxjs": "~7.8.1",
    "sass": "^1.77.8",
    "tslib": "^2.6.3",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.1.4",
    "@angular/cli": "~18.1.4",
    "@angular/compiler-cli": "^18.1.4",
    "@playwright/test": "^1.46.0",
    "@types/bootstrap": "^5.2.10",
    "@types/jasmine": "~5.1.4",
    "@types/node": "^22.2.0",
    "jasmine-core": "~5.2.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "source-map-explorer": "^2.5.3",
    "typescript": "~5.5.4",
    "webpack-bundle-analyzer": "^4.10.2"
  }
}
