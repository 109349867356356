import { NgFor } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import {
  CarouselCaptionComponent,
  CarouselComponent,
  CarouselControlComponent,
  CarouselIndicatorsComponent,
  CarouselInnerComponent,
  CarouselItemComponent,
  ThemeDirective
} from '@coreui/angular';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  highlightslides: any[] = new Array(4).fill({ id: -1, src: '', title: '', subtitle: '' });
  sponsorslides: any[] = new Array(4).fill({ id: -1, src: '', title: '', subtitle: '' });

  ngOnInit(): void {
    // Define Highlight photo array
    this.highlightslides[0] = {
      id: 0,
      src: './assets/images/2024/actionphotos/20241005-Wilsonville-vs-Central-21-Riddick-Molatore-strips-the-ball-from-Central-QB-JT-Girod-5.jpg',
      title: 'STRIP SACK!',
      subtitle: '#21 Riddick Molatore strips the ball from Central QB JT Girod'
    };
    this.highlightslides[1] = {
      id: 1,
      src: './assets/images/2024/actionphotos/20241005-Wilsonville-vs-Central-7-Carter-Christiansen-and-the-defense-stuffs-a-Central-run.jpg',
      title: 'STUFFED',
      subtitle: '#7 Carter Christiansen and the defense stuffs a Central run'
    };
    this.highlightslides[2] = {
      id: 2,
      src: './assets/images/2024/actionphotos/20240907_WilsonvillevsNelson_Mark-Wiepert-breaks-down-the-sideline_7192.jpg',
      title: 'BREAKING FREE',
      subtitle: '#1 Mark Wiepert breaks down the sideline'
    };

    this.highlightslides[3] = {
      id: 3,
      src: './assets/images/2024/actionphotos/20240907_WilsonvillevsNelson_Nick-Summers-and-Lincoln-Mason-chase-Nelson QB Avirey Durdahl_7178.jpg',
      title: 'PRESSURE',
      subtitle: '#56 Nick Summers and #66 Lincoln Mason bringing pressure Nelson QB Avirey Durdahl'
    };

    // Define Sponsors array
    this.sponsorslides[0] = {
      id: 0,
      src: '/assets/images/sponsors/tzmedical.png',
      title: 'TZ Medical Inc.',
      subtitle: 'Touchdown Sponsor'
    };
    this.sponsorslides[1] = {
      id: 1,
      src: '/assets/images/sponsors/marquis.png',
      title: 'Marquis',
      subtitle: 'Touchdown Sponsor'
    };
    this.sponsorslides[2] = {
      id: 2,
      src: '/assets/images/sponsors/ruppfamilybuildersdark.png',
      title: 'Rupp Family Builders',
      subtitle: 'Touchdown Sponsor'
    };
    this.sponsorslides[3] = {
      id: 3,
      src: '/assets/images/sponsors/swire-coca-cola-logo-16X9.png',
      title: 'Swire Coca-Cola',
      subtitle: 'Touchdown Sponsor'
    };
    this.sponsorslides[4] = {
      id: 4,
      src: '/assets/images/sponsors/westernpartitionsincorporated.png',
      title: 'WPI Builds',
      subtitle: 'Touchdown Sponsor'
    };
    this.sponsorslides[5] = {
      id: 5,
      src: '/assets/images/sponsors/grahamandtooze.jpg',
      title: 'Graham & Tooze Farm Store',
      subtitle: 'Field Goal Sponsor'
    };
    this.sponsorslides[6] = {
      id: 6,
      src: '/assets/images/sponsors/gradynelsonpremierpropertygroup.png',
      title: 'Grady Nelson - Premiere Property Group',
      subtitle: 'Touchdown Sponsor'
    };
    this.sponsorslides[7] = {
      id: 7,
      src: '/assets/images/sponsors/lesschwabtires.png',
      title: 'Les Schwab - Wilsonville',
      subtitle: 'Touchdown Sponsor'
    };
    this.sponsorslides[8] = {
      id: 8,
      src: '/assets/images/sponsors/lancessuperiorautoservice.jpg',
      title: "Lance/'s Superior Auto Services",
      subtitle: 'Field Goal Sponsors'
    };
    this.sponsorslides[9] = {
      id: 9,
      src: '/assets/images/sponsors/able-pay-health.png',
      title: 'Able Pay',
      subtitle: 'Field Goal Sponsor'
    };
    this.sponsorslides[10] = {
      id: 10,
      src: '/assets/images/sponsors/roc-regenerative-orthopedic-center-logo.svg',
      title: 'Regenerative Orthopedic Center',
      subtitle: 'Field Goal Sponsor'
    };
    this.sponsorslides[11] = {
      id: 11,
      src: '/assets/images/sponsors/state-farm-jake-puppo-16x9.jpg',
      title: 'Jay Puppo State Farm Insurance',
      subtitle: 'Field Goal Sponsor'
    };
    this.sponsorslides[12] = {
      id: 12,
      src: '/assets/images/sponsors/crowleylandscape.png',
      title: 'Crowley Landscape Management',
      subtitle: 'First Down Sponsor'
    };
    this.sponsorslides[13] = {
      id: 13,
      src: '/assets/images/sponsors/mcconnaughey-real-estate.webp',
      title: 'McCounnaughey Real Estate',
      subtitle: 'First Down Sponsor'
    };
    this.sponsorslides[14] = {
      id: 14,
      src: '/assets/images/sponsors/resticorthodontics.jpg',
      title: 'Wilsonville Orthodontics - Dr. Restic',
      subtitle: 'First Down Sponsor'
    };
    this.sponsorslides[15] = {
      id: 15,
      src: '/assets/images/sponsors/wilsonville-carpet-tile.jpg',
      title: 'Wilsonville Carpet and Tile',
      subtitle: 'First Down Sponsor'
    };
    this.sponsorslides[16] = {
      id: 16,
      src: '/assets/images/sponsors/mcdonalds.jpg',
      title: 'McDonalds Greentree Enterprises',
      subtitle: 'First Down Sponsor'
    };
    this.sponsorslides[17] = {
      id: 17,
      src: '/assets/images/sponsors/barrierpestcontrol2.png',
      title: 'Barrier Pest Control',
      subtitle: 'First Down Sponsor'
    };
    this.sponsorslides[18] = {
      id: 18,
      src: '/assets/images/sponsors/laueteamrealestate.jpg',
      title: 'Laue Team Real Estate',
      subtitle: 'First Down Sponsor'
    };
    this.sponsorslides[19] = {
      id: 19,
      src: '/assets/images/sponsors/wentworthchevy.jpg',
      title: 'Wentworth Chevy',
      subtitle: 'First Down Sponsor'
    };
    this.sponsorslides[20] = {
      id: 20,
      src: '/assets/images/sponsors/parker-johnstones-wilsonville-honda-white.png',
      title: "Parker Johnstone's Wilsonville Honda",
      subtitle: 'First Down Sponsor'
    };
    this.sponsorslides[21] = {
      id: 21,
      src: '/assets/images/sponsors/willamette-falls-financial-16x9.jpg',
      title: 'Willamette Falls Financial',
      subtitle: 'First Down Sponsor'
    };
    this.sponsorslides[22] = {
      id: 22,
      src: '/assets/images/sponsors/stafford-beverage-logo.jpg',
      title: 'Stafford Beverage',
      subtitle: 'First Down Sponsor'
    };
    this.sponsorslides[23] = {
      id: 23,
      src: '/assets/images/sponsors/flawless-aesthetics-logo-16x9.png',
      title: 'Flawless Aesthetics',
      subtitle: 'First Down Sponsor'
    };
    this.sponsorslides[24] = {
      id: 24,
      src: '/assets/images/sponsors/therapeutic-associates-physical-therapy-logo-16x9.png',
      title: 'Therapeutic Associates Physical Therapy',
      subtitle: 'First Down Sponsor'
    };
    this.sponsorslides[25] = {
      id: 25,
      src: '/assets/images/sponsors/kyle-bunch-goosehead-insurance-16x9.jpg',
      title: 'Kyle Bunch - Goosehead Insurance',
      subtitle: 'First Down Sponsor'
    };
    this.sponsorslides[26] = {
      id: 26,
      src: '/assets/images/sponsors/active-water-sports-logo-16x9.png',
      title: 'Active Water Sports',
      subtitle: 'Field Goal Sponsor'
    };
    this.sponsorslides[27] = {
      id: 27,
      src: '/assets/images/sponsors/audi-wilsonville-logo-16x9.png',
      title: 'Audi Wilsonville',
      subtitle: 'Field Goal Sponsor'
    };
    this.sponsorslides[28] = {
      id: 28,
      src: '/assets/images/sponsors/ups-store-logo-16x9.png',
      title: 'UPS Store - Wilsonville',
      subtitle: 'Field Goal Sponsor'
    };

  }
}
